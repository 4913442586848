//------------------------------------------------------------------------------
// IMPORTS

@import "func";
@import "variables";
@import "grid";
@import "datatables";
@import "fancybox";


//------------------------------------------------------------------------------
// DEFAULTS

* {
  box-sizing: border-box;
}

main {
  display: block;
}

[hidden] {
  display: none!important;
}


//------------------------------------------------------------------------------
// PRINT

@media print {
  * {
    background: transparent!important;
    border-color: black!important;
    color: black!important;
    text-shadow: none!important;
  }

  .hidden-print {
    display: none!important;
  }

  @page {
    margin: 1cm 0.5cm;
  }
}


//------------------------------------------------------------------------------
// HELPERS

.hidden {
  display: none;
}

.nowrap {
  white-space: nowrap;
}

.loading-spinner {
  animation: loading-spinner 1s linear infinite;
  border: rem(4) solid $brand-color-5;
  border-radius: 50%;
  border-right-color: rgba($brand-color-5, 0.5);
  border-top-color: rgba($brand-color-5, 0.5);
  display: block;
  height: rem(24);
  margin-left: auto;
  opacity: 1;
  transition: 10ms all;
  visibility: visible;
  width: rem(24);

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  @keyframes loading-spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

// Accessibility

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: rem(1);
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: rem(1);
}


//------------------------------------------------------------------------------
// HTML / BODY

html {
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  text-size-adjust: 100%;
}

.md-font {
  font-size: rem($font-size-min);
  line-height: $line-height;

  @media (min-width: $break-sm-rem) {
    font-size: calc(#{rem($font-size-min)} + #{($font-size-max - $font-size-min) / 16} * (100vw - #{$break-sm-rem}) / (#{rem-unitless($break-xl)} - #{rem-unitless($break-sm)}));
  }

  @media (min-width: $break-xl-rem) {
    font-size: rem($font-size-max);
  }
}

.lg-font {
  font-size: rem($font-size-lg-min);
  line-height: $line-height;

  @media (min-width: $break-sm-rem) {
    font-size: calc(#{rem($font-size-lg-min)} + #{($font-size-lg-max - $font-size-lg-min) / 16} * (100vw - #{$break-sm-rem}) / (#{rem-unitless($break-xl)} - #{rem-unitless($break-sm)}));
  }

  @media (min-width: $break-xl-rem) {
    font-size: rem($font-size-lg-max);
  }
}

body {
  @extend .md-font;
  color: $text-color;
  font-feature-settings: "liga", "kern";
  font-kerning: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  min-width: rem(320);
  text-rendering: geometricPrecision;
}


//------------------------------------------------------------------------------
// MESSAGES

.messages {
  list-style: none;
  padding: 0;

  li {
    background-position: left rem(10) center;
    background-repeat: no-repeat;
    background-size: rem(24);
    color: $light-text-color;
    padding: rem(10) rem(10) rem(10) rem(44);
  }

  .error {
    background-color: $brand-color-1;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='%23FFFFFF' d='M13,13H11V7H13M13,17H11V15H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z' /></svg>");
  }

  .success {
    background-color: $brand-color-11;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='%23FFFFFF' d='M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M11,16.5L18,9.5L16.59,8.09L11,13.67L7.91,10.59L6.5,12L11,16.5Z' /></svg>");
  }
}


//------------------------------------------------------------------------------
// HEADING

.h1 {
  color: $brand-color-4;
  font-size: rem($font-size-h1-min);
  line-height: 1.1;
  margin: rem(20) 0 rem(16);
  text-transform: uppercase;

  @media (min-width: $break-sm-rem) {
    font-size: calc(#{rem($font-size-h1-min)} + #{($font-size-h1-max - $font-size-h1-min) / 16} * (100vw - #{$break-sm-rem}) / (#{rem-unitless($break-xl)} - #{rem-unitless($break-sm)}));
  }

  @media (min-width: $break-xl-rem) {
    font-size: rem($font-size-h1-max);
  }
}

h1 {
  @extend .h1;
}

.h2 {
  color: $brand-color-4;
  font-size: rem($font-size-h2-min);
  text-transform: uppercase;

  @media (min-width: $break-sm-rem) {
    font-size: calc(#{rem($font-size-h2-min)} + #{($font-size-h2-max - $font-size-h2-min) / 16} * (100vw - #{$break-sm-rem}) / (#{rem-unitless($break-xl)} - #{rem-unitless($break-sm)}));
  }

  @media (min-width: $break-xl-rem) {
    font-size: rem($font-size-h2-max);
  }
}

h2 {
  @extend .h2;
  margin: rem(20) 0 rem(16);
}

.h3 {
  color: $brand-color-4;
  font-size: rem($font-size-h3-min);

  @media (min-width: $break-sm-rem) {
    font-size: calc(#{rem($font-size-h3-min)} + #{($font-size-h3-max - $font-size-h3-min) / 16} * (100vw - #{$break-sm-rem}) / (#{rem-unitless($break-xl)} - #{rem-unitless($break-sm)}));
  }

  @media (min-width: $break-xl-rem) {
    font-size: rem($font-size-h3-max);
  }
}

h3 {
  @extend .h3;
  margin: rem(20) 0 rem(16);
}

h4,
h5 {
  color: $brand-color-4;
  font-size: rem($font-size-h4-min);
  margin: rem(20) 0 rem(16);

  @media (min-width: $break-sm-rem) {
    font-size: calc(#{rem($font-size-h4-min)} + #{($font-size-h4-max - $font-size-h4-min) / 16} * (100vw - #{$break-sm-rem}) / (#{rem-unitless($break-xl)} - #{rem-unitless($break-sm)}));
  }

  @media (min-width: $break-xl-rem) {
    font-size: rem($font-size-h4-max);
  }
}


//------------------------------------------------------------------------------
// LINKS

a {
  color: $text-color;
  transition: color 250ms;

  &:hover {
    color: $brand-color-1;
    text-decoration: none;
  }

  &:focus {
    outline: rem(2) solid $brand-color-4;
    outline-offset: rem(5);
  }

  &[data-focus-method="mouse"],
  &[data-focus-method="touch"] {
    outline: 0;
  }

  // Edit / delete

  &.edit {
    fill: $text-color;
  }

  &.delete {
    fill: $brand-color-1;
  }

  &.edit,
  &.delete {
    display: inline-block;
    height: rem(30);
    outline-offset: 0;
    padding: rem(3) rem(2);
    width: rem(28);

    svg {
      height: rem(24);
      width: rem(24);
    }

    .text {
      display: none;
    }
  }
}


//------------------------------------------------------------------------------
// ABSATZ / AUFLISTUNGEN

p {
  margin: 0 0 rem(16) 0;
}

ul,
ol {
  list-style: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><rect fill='%23E3000F' height='7' width='7' x='0' y='1'/></svg>");
  margin: 0 0 rem(16) 0;
  padding-left: rem(17);

  li {
    & > ul,
    & > ol {
      margin: 0;
    }
  }
}


//------------------------------------------------------------------------------
// HERVORHEBUNGEN

abbr {
  text-decoration: none;
}

small {
  font-size: 90%;
}


//------------------------------------------------------------------------------
// DIALOG

.dialog {
  background: $brand-color-3;
  border: rem(2) solid lighten($brand-color-2, 10%);
  flex: 0 1 rem(880);
  margin: auto;
  opacity: 0;
  padding: rem(25);
  position: relative;
  transform: translateY(rem(-20));
  transition:
    200ms opacity,
    450ms transform,
    200ms visibility;
  visibility: hidden;
  z-index: 2000;

  &-sm {
    max-width: rem(500);
  }

  &-overlay {
    -webkit-backdrop-filter: blur(rem(5));
    background: rgba($body-color, 0.9);
    bottom: 0;
    cursor: pointer;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition:
      450ms opacity,
      450ms visibility;
    visibility: hidden;
    z-index: 1000;
  }

  &-wrapper {
    align-items: center;
    bottom: 0;
    display: flex;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: 600ms opacity;
    visibility: hidden;
    z-index: 1000;

    &.show {
      opacity: 1;
      transition: 450ms opacity;
      visibility: visible;

      .dialog {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
      }

      .dialog-overlay {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &-scroll {
    align-items: center;
    bottom: 0;
    display: flex;
    left: 0;
    min-width: rem($container-min-width);
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    padding: rem($col-min-gutter);
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1010;
  }

  &-title {
    @extend .h1;
    margin-top: 0;
  }

  &-main {
    margin-bottom: rem(20);
  }

  &-footer {
    border-top: rem(2) solid $brand-color-2;
    margin: rem(10) 0 0;
    padding: rem(20) 0 0;
  }
}


//------------------------------------------------------------------------------
// FORM: DEFAULTS

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  margin: 0;
}

.legend {
  @extend .h2;
  padding: rem(10) 0 rem(10);
  position: relative;
  width: 100%;

  &:after {
    background: $brand-color-1;
    content: "";
    height: rem(10);
    left: rem(-17);
    margin-top: rem(-5);
    position: absolute;
    top: 50%;
    width: rem(10);
  }

  &-sub {
    align-items: center;
    display: inline-flex;
    min-height: rem(24);
    padding: 0 0 rem(5);
  }
}

label {
  cursor: pointer;
}

input {
  appearance: none;
  border-radius: 0;
  box-shadow: none;
  font-family: inherit;
  margin: 0;

  &[type="date"],
  &[type="datetime-local"] {
    appearance: none;
    cursor: text;
    min-width: 100%; // iOS-Bugfix
  }

  &[type="radio"] {
    appearance: auto;
  }

  //&[type="number"] {
  //  appearance: textfield;
  //}

  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
    opacity: 0;
  }

  &::-webkit-clear-button,
  &::-ms-clear {
    display: none;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    cursor: pointer;
    opacity: 0;
  }

  &::-webkit-contacts-auto-fill-button,
  &::-webkit-credentials-auto-fill-button {
    pointer-events: none;
    visibility: hidden;
  }

  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  &[disabled] {
    cursor: not-allowed;
  }
}

select {
  border-radius: 0;
  cursor: pointer;
  font-family: inherit;

  &::-ms-expand {
    display: none;
  }

  &:focus {
    &::-ms-value {
      background: transparent; // Disable option background color
    }
  }

  &[disabled] {
    cursor: not-allowed;
  }
}

textarea {
  font-family: inherit;

  &[disabled] {
    cursor: not-allowed;
  }
}

button {
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  overflow: hidden;
  user-select: none;
}

.non-field-errors {
  background: $body-color;
  border: rem(1) solid $brand-color-1;
  color: $brand-color-1;
  margin-bottom: rem(18);
  padding: rem(14);

  ul {
    margin: 0;
  }
}


//------------------------------------------------------------------------------
// FORM: FIELDS

.field {
  margin: 0 0 rem(12);

  &-md-bottom {
    align-items: flex-end;
    display: flex;
  }

  &-label {
    align-items: center;
    display: inline-flex;
    margin: 0 0 rem(5);
    min-height: rem(24);
  }

  &-required {
    color: $brand-color-1;
    margin-left: rem(3);
  }

  &-error-message {
    display: none;

    &.show {
      color: $brand-color-1;
      display: block;
      margin: rem(6) 0 rem(4);
    }
  }

  &-warning {
    display: none;
    height: rem(24);
    left: rem(-3);
    margin-left: auto;
    position: relative;
    width: rem(24);

    &.show {
      display: inline-block;
    }

    svg {
      fill: $brand-color-4;
      height: rem(24);
      width: rem(24);
    }
  }

  &-input {
    background: $body-color;
    border: rem(1) solid $brand-color-2;
    color: $text-color;
    font-size: rem($font-size-input);
    height: rem(32);
    padding: rem(4) rem(8);
    width: 100%;

    &:focus {
      border-color: darken($brand-color-4, 5%);
      box-shadow: 0 0 0 rem(1) $brand-color-4;
      outline: 0;
    }

    &[disabled],
    &[readonly] {
      background: rgba($brand-color-2, 0.25);
    }

    &[type="date"],
    &[type="number"]:not([readonly]):not([disabled]) {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'><path fill='%231A1A1A' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>");
      background-position: right rem(10) center;
      background-repeat: no-repeat;
      background-size: rem(8) rem(10);
    }
  }

  &-select {
    appearance: none;
    background-color: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'><path fill='%231A1A1A' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>");
    background-position: right rem(10) center;
    background-repeat: no-repeat;
    background-size: rem(8) rem(10);
    border: 0;
    color: $text-color;
    font-size: rem($font-size-input);
    height: rem(24);
    outline: 0;
    padding-right: rem(20);
    position: relative;
    width: 100%;
    z-index: 10;

    &-wrapper {
      display: flex;
      padding: rem(4) rem(1) rem(4) rem(10);
      position: relative;
    }

    &-inline-wrapper {
      display: inline-flex;
      padding: rem(4) rem(1) rem(4) rem(10);
      position: relative;
    }

    &-overlay {
      background: $body-color;
      border: rem(1) solid $brand-color-2;
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    &:focus {
      & + .field-select-overlay {
        border-color: darken($brand-color-4, 5%);
        box-shadow: 0 0 0 rem(1) $brand-color-4;
      }
    }

    &[disabled] {
      & + .field-select-overlay {
        background: rgba($brand-color-2, 0.25);
        cursor: not-allowed;
      }
    }

    option {
      color: $text-color;
    }
  }

  &-textarea {
    background: $body-color;
    border: rem(1) solid $brand-color-2;
    color: $text-color;
    display: block;
    font-size: rem($font-size-input);
    line-height: 1.6;
    outline: 0;
    padding: rem(10) rem(8);
    resize: vertical;
    width: 100%;

    &:focus {
      border-color: darken($brand-color-4, 5%);
      box-shadow: 0 0 0 rem(1) $brand-color-4;
    }

    &[disabled] {
      background: rgba($brand-color-2, 0.25);
    }
  }

  &-file {
    background: $body-color;
    border: rem(1) solid $brand-color-2;
    outline: 0;
    padding: rem(6) rem(8);
    width: 100%;

    &:focus {
      border-color: darken($brand-color-4, 5%);
      box-shadow: 0 0 0 rem(1) $brand-color-4;
    }

    &[disabled] {
      background: rgba($brand-color-2, 0.25);
    }

    &-choose {
      text-align: center;

      .icon {
        fill: $brand-color-2;
        height: rem(48);
        margin: 0 auto rem(5);
        width: rem(48);

        svg {
          height: rem(48);
          width: rem(48);
        }
      }
    }

    &-preview {
      display: inline-block;
      margin-bottom: rem(8);
    }

    &-clear {
      margin-left: rem(10);
    }

    &-output {
      ul {
        border-top: rem(1) solid lighten($brand-color-2, 5%);
        list-style: none;
        padding: 0;
      }

      li {
        align-items: center;
        border-bottom: rem(1) solid lighten($brand-color-2, 5%);
        display: flex;
        padding: rem(4);

        .file-name {
          margin-right: rem(10);
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .file-category {
          margin-left: auto;
          margin-right: rem(10);

          & + .delete {
            margin: 0;
          }
        }

        .file-size {
          margin-left: auto;
          white-space: nowrap;
        }

        .delete {
          margin-left: auto;
        }
      }
    }

    &-multiple {
      &-wrapper {
        background: $body-color;
        cursor: pointer;
        min-height: rem(32);
        padding: rem(10) rem(8);
        position: relative;

        input[type="file"] {
          bottom: 0;
          cursor: pointer;
          left: 0;
          opacity: 0;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
          z-index: 10;

          &:focus {
            & ~ .field-file-overlay {
              border-color: darken($brand-color-4, 5%);
              box-shadow: 0 0 0 rem(1) $brand-color-4;
            }
          }
        }

        &.disabled {
          background: rgba($brand-color-2, 0.25);

          input[type="file"] {
            cursor: not-allowed;
          }
        }
      }
    }

    &-overlay {
      border: rem(1) solid $brand-color-2;
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &-radiobutton {
    break-inside: avoid;
    display: inline-flex;
    margin: 0 rem(5) rem(8) rem(12.5);
    position: relative;

    @media (min-width: $break-md-em) {
      .field-md-bottom & {
        margin-bottom: rem(18);
      }
    }

    input[type="radio"] {
      &[disabled] {
        & ~ .field-radiobutton {
          background: rgba($brand-color-2, 0.25);
          cursor: not-allowed;
        }
      }

      & ~ .field-radiobutton-title {
        margin-left: rem(5);
      }

      &-wrapper {
        margin-bottom: rem(3);
        margin-left: rem(3);
      }

      &-error-message {
        &.show {
          margin: 0 0 rem(10);
        }
      }
    }
  }

  &-checkbox {
    break-inside: avoid;
    display: inline-flex;
    margin: 0 rem(5) rem(8) 0;
    position: relative;

    @media (min-width: $break-md-em) {
      .field-md-bottom & {
        margin-bottom: rem(18);
      }
    }

    input[type="checkbox"] {
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;

      &[disabled] {
        & ~ .field-checkbox-indicator {
          background: rgba($brand-color-2, 0.25);
          cursor: not-allowed;
        }
      }

      &.invalid {
        & ~ .field-checkbox-indicator {
          border-color: $brand-color-1;
        }
      }

      &:focus {
        & ~ .field-checkbox-indicator {
          border-color: darken($brand-color-4, 5%);
          box-shadow: 0 0 0 rem(1) $brand-color-4;
        }
      }

      &:checked ~ .field-checkbox-indicator {
        &:after {
          border: solid $text-color;
          border-width: 0 rem(3) rem(3) 0;
          content: "";
          height: rem(9);
          left: rem(3);
          margin: rem(2);
          position: absolute;
          top: rem(-1);
          transform: rotate(45deg);
          width: rem(3);
        }
      }
    }

    &-wrapper {
      margin-bottom: rem(3);
    }

    &-indicator {
      background: $light-text-color;
      border: rem(1) solid $brand-color-2;
      display: block;
      flex: 0 0 rem(20);
      height: rem(18);
      margin: rem(2) rem(7) 0 0;
      max-width: rem(18);
      position: relative;
      width: rem(18);
    }

    &-error-message {
      &.show {
        margin: 0 0 rem(10);
      }
    }
  }

  &-button {
    align-items: center;
    background: $brand-color-1;
    border: rem(1) solid darken($brand-color-1, 5%);
    color: $light-text-color;
    display: inline-flex;
    height: rem(32);
    outline: 0;
    padding: rem(3) rem(10);
    text-decoration: none;
    text-transform: uppercase;
    user-select: none;

    @media (min-width: $break-sm-em) {
      padding-left: rem(15);
      padding-right: rem(15);
    }

    &:focus {
      background: $brand-color-4;
      border-color: darken($brand-color-4, 10%);
    }

    &[data-focus-method="mouse"],
    &[data-focus-method="touch"] {
      &:focus {
        background: darken($brand-color-1, 5%);
        border-color: darken($brand-color-1, 10%);
      }
    }

    &:hover {
      background: darken($brand-color-1, 5%);
      border-color: darken($brand-color-1, 10%);
      color: $light-text-color;
    }

    &.success {
      background: $brand-color-5;
      border: rem(1) solid darken($brand-color-5, 5%);
    }

    svg {
      fill: $light-text-color;
      height: rem(24);
      left: rem(-4);
      margin-right: rem(2);
      position: relative;
      width: rem(24);

      @media (min-width: $break-sm-em) {
        left: 0;
        margin-right: rem(8);
      }
    }

    &-no-text {
      padding: rem(3);

      svg {
        left: 0;
        margin: 0;
      }
    }

    &-inline {
      margin: rem(7) 0 rem(10);

      @media (min-width: $break-md-em) {
        margin: rem(29) 0 0;
      }
    }

    &-mobile {
      padding: rem(3);
      width: rem(32);

      svg {
        left: 0;
        margin: 0;
      }

      .text {
        display: none;
      }

      @media (min-width: $break-md-em) {
        padding: rem(3) rem(15);
        width: auto;

        svg {
          margin-right: rem(8);
        }

        .text {
          display: inline-block;
        }
      }
    }

    &-cancel {
      background: transparent;
      border: rem(1) solid transparent;
      color: $brand-color-4;
      display: block;
      font-size: rem($font-size-input);
      line-height: rem(30);
      padding: 0 rem(15);
      text-decoration: none;
      text-transform: uppercase;
      transition: none;
      user-select: none;

      &:focus {
        background: $brand-color-4;
        border-color: darken($brand-color-4, 5%);
        color: $light-text-color;
        outline: 0;
      }

      &[data-focus-method="mouse"],
      &[data-focus-method="touch"] {
        &:focus {
          background: transparent;
          border-color: transparent;
          color: $brand-color-1;
        }
      }
    }
  }

  &-attachments {
    margin-left: rem(26);
  }

  &-clipboard {
    .field-button {
      margin-left: rem(5);

      &:focus {
        outline-offset: rem(2);
      }
    }
  }
}

.form {
  &-errornote {
    color: $brand-color-1;
  }

  &-footer {
    border-top: rem(2) solid $brand-color-2;
    margin: rem(20) 0 0;
    padding: rem(20) 0 rem(5);
    text-align: right;
  }
}


//------------------------------------------------------------------------------
// TABLE

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: rem($font-size-table);
  margin: rem(20) 0 rem(16);
  width: 100%;
}

th {
  text-align: left;
}

th,
td {
  border-bottom: rem(1) solid $brand-color-7;
  color: $text-color;
  padding: rem(9) rem(6);
  vertical-align: top;

  &:focus {
    background: $brand-color-4;
    color: $light-text-color;
    outline: 0;
  }

  &[data-focus-method="mouse"],
  &[data-focus-method="touch"] {
    &:focus {
      background: transparent;
      color: $text-color;
      outline: 0;
    }
  }

  &:first-child {
    padding-left: rem(12);
  }

  &:last-child {
    padding-right: rem(12);
  }

  &.edit {
    border-right: 0;
    padding: 0 rem(5) 0 0;
    width: rem(28);
  }

  &.delete {
    padding: 0 rem(5) 0 0;
    width: rem(33);
  }

  a {
    &:focus {
      outline-offset: rem(2);
    }
  }
}

thead {
  th {
    border-left: rem(1) solid $brand-color-7;
    vertical-align: bottom;

    &:first-child {
      border-left: 0;
    }
  }
}

tbody {
  display: table-footer-group;

  tr:hover {
    th,
    td {
      background: lighten($brand-color-2, 17%);
    }
  }
}

tfoot {
  display: table-header-group;

  th {
    background: lighten($brand-color-2, 20%);
    border-top: rem(1) solid $brand-color-7;
    padding: rem(6) rem(6);
  }
}


//------------------------------------------------------------------------------
// IMAGE

svg {
  height: 100%;
  transform: scale(1); // Firefox: Subpixel-Bugfix
  width: 100%;
}


//------------------------------------------------------------------------------
// HEADER / MAIN

.header {
  padding: rem(15) 0 rem(20);

  @media (min-width: $break-lg-rem) {
    background: $body-color;
    box-shadow: 0 rem(15) rem(10) rem(-15) rgba($text-color, 0.15);
    height: rem(79);
    left: rem(-10);
    padding: rem(15) rem(10) rem(12);
    position: fixed;
    right: rem(-10);
    top: 0;
    z-index: 1000;
  }
}

.main {
  @media (min-width: $break-lg-rem) {
    margin-top: rem(109);
  }

  &-nav {
    border-bottom: rem(1) solid $brand-color-4;
    list-style: none;
    margin: rem(20) 0 rem(3);
    padding: 0;
    user-select: none;

    @media (min-width: $break-lg-rem) {
      align-items: center;
      border: 0;
      display: flex;
      margin: 0;
      position: relative;
      top: rem(-2);
    }

    &-toggle {
      background: $brand-color-7;
      display: block;
      height: rem(32);
      margin-left: auto;
      padding: rem(4);
      transition: 250ms background;
      width: rem(32);

      @media (min-width: $break-lg-rem) {
        display: none;
      }

      &:hover {
        background: $brand-color-4;
      }

      svg {
        fill: $light-text-color;
        height: rem(24);
        width: rem(24);
      }
    }

    &-wrapper {
      @media (min-width: $break-lg-rem) {
        &.hidden {
          display: block;
        }
      }
    }

    &-link {
      display: flex;
      line-height: rem(24);
      padding: rem(6) 0 rem(6) rem(4);
      text-decoration: none;
      text-transform: uppercase;
      transition: color 250ms;

      @media (min-width: $break-lg-rem) {
        display: block;
        line-height: rem(44);
        padding: 0;
        position: relative;
      }

      &.active {
        color: $brand-color-1;
      }

      &:focus {
        outline-offset: 0;
        z-index: 100;
      }

      .title {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
      }

      .icon {
        display: none;
        fill: $brand-color-1;
        flex: 0 0 rem(24);
        height: rem(24);
        width: rem(24);
      }

      &[aria-expanded="false"] {
        .down {
          display: block;

          @media (min-width: $break-lg-rem) {
            display: none;
          }
        }

        & + .main-nav-sub {
          display: none;
        }
      }

      &[aria-expanded="true"] {
        .up {
          display: block;

          @media (min-width: $break-lg-rem) {
            display: none;
          }
        }

        & + .main-nav-sub {
          display: block;
        }
      }
    }

    &-item {
      border-top: rem(1) solid $brand-color-4;

      @media (min-width: $break-lg-rem) {
        border: 0;
        margin-right: rem(25);
      }

      &.user {
        line-height: rem(24);
        margin: 0;
        padding: rem(6) 0 rem(6) rem(2);
        text-transform: uppercase;

        svg {
          fill: $brand-color-1;
          height: rem(24);
          margin-right: rem(8);
          width: rem(24);
        }
      }

      &.space-left {
        margin: 0 0 0 auto;
      }

      &.docs {
        line-height: rem(24);
        text-transform: uppercase;
        margin-right: rem(24);
      }

      &.logout {
        @media (min-width: $break-lg-rem) {
          margin: 0 0 0 rem(10);
          position: relative;
          right: rem(-9);
        }

        a {
          display: flex;
          fill: $text-color;
          line-height: rem(24);
          padding: rem(6) 0 rem(6) rem(2);
          text-decoration: none;
          text-transform: uppercase;
          transition: color 250ms;

          @media (min-width: $break-lg-rem) {
            display: block;
            height: rem(44);
            line-height: 1;
            padding: rem(10);
            transition: fill 250ms;
            width: rem(44);
          }

          svg {
            height: rem(24);
            margin-right: rem(8);
            width: rem(24);
          }

          &:hover {
            fill: $brand-color-1;
          }

          &:focus {
            outline-offset: rem(-6);
          }
        }

        .hidden {
          display: block;

          @media (min-width: $break-lg-rem) {
            display: none;
          }
        }
      }
    }

    &-sub {
      list-style: none;
      margin: 0 0 rem(7);
      padding: 0;

      a {
        display: block;
        padding: rem(2) rem(4);
        text-decoration: none;
        text-transform: uppercase;
        transition: color 250ms;

        &.active {
          color: $brand-color-1;
        }

        &:focus {
          outline-offset: 0;
        }
      }

      @media (min-width: $break-lg-rem) {
        background: $brand-color-4;
        display: none;
        margin: 0 0 0 rem(-17);
        padding: rem(15) 0;
        position: absolute;
        z-index: 50;

        a {
          color: $light-text-color;
          display: block;
          padding: rem(5) rem(20);
          text-decoration: none;
          text-transform: uppercase;
          transition: color 250ms;

          &:hover {
            color: $brand-color-2;
          }

          &:focus {
            background: rgba($brand-color-2, 0.3);
            color: $light-text-color;
            outline: 0;
          }

          &.active {
            background: rgba($brand-color-2, 0.3);
            color: $light-text-color;
          }
        }
      }
    }
  }

  &-content {
    background: lighten($brand-color-2, 23%);
    border: rem(2) solid $brand-color-2;
    margin-bottom: rem(60);
    padding: rem(10) rem(15) rem(20);

    @media (min-width: $break-md-rem) {
      padding: rem(10) rem(30) rem(25);
    }

    @media (min-width: $break-xl-rem) {
      padding: rem(20) rem(40) rem(30);
    }

    @media print {
      background: none;
      border: 0;
      margin: 0;
      padding: 0;
    }
  }
}

.summary-title-verify {
  display: flex;
  justify-content: space-between;
}

.logo {
  align-items: flex-end;
  display: flex;
  justify-content: center;
  text-align: right;
  text-decoration: none;
  text-transform: uppercase;
  user-select: none;

  @media (min-width: $break-md-rem) {
    position: relative;
    top: rem(4);
  }

  img {
    display: block;
    user-select: none;
  }

  &-subtitle {
    color: $brand-color-1;
    margin: 0 0 0 rem(10);
    position: relative;
    top: rem(1);
  }
}


//------------------------------------------------------------------------------
// SUB NAVIGATION

.sub {
  &-nav-wrapper {
    width: 100%;
  }

  &-container {
    background: darken($brand-color-3, 5%);
    margin-bottom: rem(20);
    padding-top: rem(20);

    @media (min-width: $break-lg-rem) {
      top: rem(109);

      &.sticky {
        position: sticky;
      }

      &.hidden {
        display: block;
      }
    }

    .sub-title {
      border-bottom: rem(1) solid $brand-color-7;
      margin: 0 rem(20) rem(14);
      padding: rem(2) 0 rem(6);

      &-act {
        @media (min-width: $break-md-rem) {
          margin-right: 0;
        }

        @media (min-width: $break-lg-rem) {
          margin-right: rem(20);
        }
      }
    }
  }

  &-nav {
    list-style: none;
    margin: 0;
    padding: 0 0 rem(10);
    user-select: none;

    li {
      margin: rem(10) 0;
    }

    a {
      color: $text-color;
      display: block;
      padding: 0 rem(20);
      position: relative;
      text-decoration: none;
      transition: color 250ms;

      &:hover {
        color: $brand-color-1;
      }

      &.active {
        color: $brand-color-1;

        &:after {
          background: $brand-color-1;
          content: "";
          height: rem(10);
          left: 0;
          margin-top: rem(-5);
          position: absolute;
          top: 50%;
          width: rem(10);
        }
      }
    }

    &-actions {
      padding: 0 0 rem(10);

      a {
        align-items: center;
        display: flex;

        &.active {
          color: $brand-color-1;

          &:after {
            display: none;
          }
        }
      }

      svg {
        fill: $brand-color-1;
        height: rem(24);
        margin-right: rem(10);
        width: rem(24);
      }
    }

    &-other-acts {
      margin-bottom: rem(20);
      padding: 0 rem(20);

      .user {
        align-items: center;
        display: flex;
        left: rem(-2);
        line-height: rem(24);
        padding: 0;
        position: relative;
        text-transform: uppercase;

        svg {
          fill: $brand-color-1;
          height: rem(24);
          margin-right: rem(4);
          width: rem(24);
        }
      }
    }

    &-toggle {
      @extend .h3;
      align-items: center;
      background: $brand-color-7;
      color: $light-text-color;
      display: flex;
      margin-bottom: rem(10);
      padding: rem(5) rem(20);
      text-decoration: none;
      text-transform: uppercase;
      transition: 250ms background;

      @media (min-width: $break-lg-rem) {
        display: none;
      }

      &:hover {
        background: $brand-color-4;
        color: $light-text-color;
      }

      .icon {
        display: none;
        fill: $light-text-color;
        height: rem(24);
        left: rem(-5);
        position: relative;
        width: rem(24);
      }

      &[aria-expanded="true"] {
        .up {
          display: block;
        }
      }

      &[aria-expanded="false"] {
        .down {
          display: block;
        }
      }
    }
  }
}

.schema {
  &-1 {
    border-top: rem(7) solid $brand-color-8;
  }

  &-2 {
    border-top: rem(7) solid $brand-color-6;
  }

  &-3 {
    border-top: rem(7) solid $brand-color-5;
  }

  &-4 {
    border-top: rem(7) solid $brand-color-4;
  }
}


//------------------------------------------------------------------------------
// BREADCRUMBS

.breadcrumbs {
  ul {
    display: flex;
    left: rem(-4);
    list-style: none;
    margin-bottom: 0;
    padding: 0;
    position: relative;
  }

  li {
    flex: 0 1 auto;
    overflow: hidden;
    padding: rem(4);
    text-overflow: ellipsis;
    white-space: nowrap;

    &:first-child {
      flex: 0 0 auto;
    }

    .separator {
      display: inline-block;
      margin-right: rem(4);
    }
  }

  a {
    outline-offset: 0;
  }
}


//------------------------------------------------------------------------------
// ERROR PAGE

.error-page {
  margin: rem(40) auto;
  max-width: rem(600);
  text-align: center;
  width: 100%;

  a {
    margin-top: rem(20);
  }
}


//------------------------------------------------------------------------------
// JQUERY TOAST

.jq {
  &-toast {
    &-wrap {
      display: block;
      letter-spacing: normal;
      min-width: rem($container-min-width);
      padding-left: rem($col-min-gutter);
      padding-right: rem($col-min-gutter);
      pointer-events: none;
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;
      z-index: 9000;

      @media (min-width: $break-sm-rem) {
        padding-left: calc(#{rem($col-min-gutter)} + #{($col-max-gutter - $col-min-gutter) / 16} * (100vw - #{$break-sm-rem}) / (#{rem-unitless($break-xl)} - #{rem-unitless($break-sm)}));
        padding-right: calc(#{rem($col-min-gutter)} + #{($col-max-gutter - $col-min-gutter) / 16} * (100vw - #{$break-sm-rem}) / (#{rem-unitless($break-xl)} - #{rem-unitless($break-sm)}));
      }

      @media (min-width: $break-md-rem) {
        max-width: rem(500);
        width: 50%;
      }

      @media (min-width: $break-xl-rem) {
        padding-left: rem($col-max-gutter);
        padding-right: rem($col-max-gutter);
      }
    }

    &-single {
      background-color: $brand-color-4;
      color: $light-text-color;
      display: block;
      margin: 0 0 rem(5);
      padding: rem(10) rem(40) em(10) rem(20);
      pointer-events: all;
      position: relative;
      width: 100%;
    }

    &-loader {
      background: darken($brand-color-4, 15%)!important;
      display: block;
      height: rem(4);
      left: 0;
      position: absolute;
      top: 0;
      width: 0;
    }

    &-loaded {
      width: 100%;
    }
  }

  &-has-icon {
    background-position: rem(10);
    background-repeat: no-repeat;
    padding-left: rem(44);
  }

  &-icon-success {
    background-color: $brand-color-10;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='%23FFFFFF' d='M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M11,16.5L18,9.5L16.59,8.09L11,13.67L7.91,10.59L6.5,12L11,16.5Z' /></svg>");
    background-position: left rem(10) center;
    background-repeat: no-repeat;
    background-size: rem(24);

    .jq-toast-loader {
      background: darken($brand-color-10, 15%)!important;
    }
  }

  &-icon-error {
    background-color: $brand-color-1;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='%23FFFFFF' d='M13,13H11V7H13M13,17H11V15H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z' /></svg>");
    background-position: left rem(10) center;
    background-repeat: no-repeat;
    background-size: rem(24);

    .jq-toast-loader {
      background: darken($brand-color-1, 15%)!important;
    }
  }

  &-icon-warning {
    background-color: $brand-color-9;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='%23FFFFFF' d='M13,13H11V7H13M13,17H11V15H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z' /></svg>");
    background-position: left rem(10) center;
    background-repeat: no-repeat;
    background-size: rem(24);

    .jq-toast-loader {
      background: darken($brand-color-1, 15%)!important;
    }
  }
}

.close-jq-toast-single {
  cursor: pointer;
  display: block;
  font-size: rem(18);
  height: rem(18);
  line-height: rem(18);
  position: absolute;
  right: rem(10);
  text-align: center;
  top: rem(5);
  width: rem(18);
}


//------------------------------------------------------------------------------
// LOGIN / PASSWORD RESET

.login {
  &-title {
    @extend .h1;
    margin-top: 0;
  }

  &-container {
    align-items: center;
    display: flex;
    height: 100vh;
  }

  &-wrapper {
    background: $brand-color-3;
    border: rem(2) solid lighten($brand-color-2, 10%);
    margin: 0 auto;
    max-width: rem(500);
    padding: rem(25);
    position: relative;
    width: 100%;
  }

  &-footer {
    border-top: rem(2) solid $brand-color-2;
    margin: rem(10) 0 0;
    padding: rem(20) 0 0;

    .field-button {
      margin-top: rem(20);

      @media (min-width: $break-sm-rem) {
        margin-top: 0;
      }
    }
  }
}

.field-help-text {
  color: $brand-color-4;
}


//------------------------------------------------------------------------------
// DATA TABLE

.datatable {
  &-wrapper {
    margin: 0 0 rem(10);
  }

  &-table {
    border-collapse: separate;

    &.collapsed {
      tbody {
        td {
          &:first-child {
            background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='%2335AA6C' d='M17,13H13V17H11V13H7V11H11V7H13V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z'/></svg>");
            background-position: left rem(12) top rem(9);
            background-repeat: no-repeat;
            background-size: rem(20) rem(20);
            cursor: pointer;
            padding-left: rem(38);

            &:focus {
              background-color: $brand-color-4;
              color: $light-text-color;
              outline: 0;
            }
          }

          &.child {
            &:first-child {
              background: transparent;
              cursor: default;
            }
          }

          &.datatable-empty {
            background: transparent;
            cursor: default;
            padding-left: rem(12);
          }
        }

        .parent {
          td {
            &:first-child {
              background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='%2335AA6C' d='M17,13H7V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z' /></svg>");
              background-position: left rem(12) top rem(9);
              background-repeat: no-repeat;
              background-size: rem(20) rem(20);
            }
          }
        }
      }

      .datatable-schema-1,
      .datatable-schema-2,
      .datatable-schema-3,
      .datatable-schema-4 {
        padding-left: 0;
      }
    }

    .icon {
      display: block;
      fill: $brand-color-1;
      height: rem(24);
      margin-left: rem(10);
      margin-top: rem(-2);
      width: rem(24);

      svg {
        height: rem(24);
        width: rem(24);
      }
    }

    .dtr-details {
      list-style: none;
      margin: rem(5) 0 0;
      padding: 0;
    }

    .dtr-title {
      display: block;
    }

    .dtr-data {
      display: block;
      margin-bottom: rem(5);

      .edit {
        margin-top: rem(10);
      }

      .delete {
        margin: 0;
      }

      .edit,
      .delete {
        align-items: center;
        display: inline-flex;
        padding-left: 0;
        padding-right: 0;
        width: auto;

        .text {
          display: inline-block;
          margin-left: rem(6);
        }
      }
    }

    .sort-1 {
      background: lighten($brand-color-2, 17%);
    }

    .sorting {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='%231A1A1A' d='M10,13V11H18V13H10M10,19V17H14V19H10M10,7V5H22V7H10M6,17H8.5L5,20.5L1.5,17H4V7H1.5L5,3.5L8.5,7H6V17Z'/></svg>");
      background-position: right rem(10) bottom rem(8);
      background-repeat: no-repeat;
      background-size: rem(20) rem(20);
      cursor: pointer;
      padding-right: rem(35);

      &:focus {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='%23FFFFFF' d='M10,13V11H18V13H10M10,19V17H14V19H10M10,7V5H22V7H10M6,17H8.5L5,20.5L1.5,17H4V7H1.5L5,3.5L8.5,7H6V17Z'/></svg>");
      }

      &[data-focus-method="mouse"],
      &[data-focus-method="touch"] {
        &:focus {
          background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='%231A1A1A' d='M10,13V11H18V13H10M10,19V17H14V19H10M10,7V5H22V7H10M6,17H8.5L5,20.5L1.5,17H4V7H1.5L5,3.5L8.5,7H6V17Z'/></svg>");
          background-position: right rem(10) bottom rem(8);
          background-repeat: no-repeat;
          background-size: rem(20) rem(20);
        }
      }
    }

    .sort-asc {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='%231A1A1A' d='M10,11V13H18V11H10M10,5V7H14V5H10M10,17V19H22V17H10M6,7H8.5L5,3.5L1.5,7H4V20H6V7Z' /></svg>");
      background-position: right rem(10) bottom rem(8);
      background-repeat: no-repeat;
      background-size: rem(20) rem(20);
      cursor: pointer;
      padding-right: rem(35);

      &:focus {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='%23FFFFFF' d='M10,11V13H18V11H10M10,5V7H14V5H10M10,17V19H22V17H10M6,7H8.5L5,3.5L1.5,7H4V20H6V7Z' /></svg>");
      }

      &[data-focus-method="mouse"],
      &[data-focus-method="touch"] {
        &:focus {
          background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='%231A1A1A' d='M10,11V13H18V11H10M10,5V7H14V5H10M10,17V19H22V17H10M6,7H8.5L5,3.5L1.5,7H4V20H6V7Z' /></svg>");
          background-position: right rem(10) bottom rem(8);
          background-repeat: no-repeat;
          background-size: rem(20) rem(20);
        }
      }
    }

    .sort-desc {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='%231A1A1A' d='M10,13V11H18V13H10M10,19V17H14V19H10M10,7V5H22V7H10M6,17H8.5L5,20.5L1.5,17H4V4H6V17Z' /></svg>");
      background-position: right rem(10) bottom rem(8);
      background-repeat: no-repeat;
      background-size: rem(20) rem(20);
      cursor: pointer;
      padding-right: rem(35);

      &:focus {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='%23FFFFFF' d='M10,13V11H18V13H10M10,19V17H14V19H10M10,7V5H22V7H10M6,17H8.5L5,20.5L1.5,17H4V4H6V17Z' /></svg>");
      }

      &[data-focus-method="mouse"],
      &[data-focus-method="touch"] {
        &:focus {
          background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='%231A1A1A' d='M10,13V11H18V13H10M10,19V17H14V19H10M10,7V5H22V7H10M6,17H8.5L5,20.5L1.5,17H4V4H6V17Z' /></svg>");
          background-position: right rem(10) bottom rem(8);
          background-repeat: no-repeat;
          background-size: rem(20) rem(20);
        }
      }
    }

    td.act-type,
    td.company-act-type {
      padding: 0;
      position: relative;
    }

    td.label {
      position: relative;
      width: rem(300);
    }

    th,
    td {
      &.hide {
        display: none;
      }
    }

    td.company-type {
      position: relative;
      width: rem(300);
    }

    .icon-attachment-wrapper {
      display: flex;
    }

    .icon-attachment {
      display: block;
      fill: $text-color;
      height: rem(24);
      width: rem(24);
    }

    .label-container {
      .label-text {
        display: block;
      }

      .icons {
        display: none;
      }

      @media (min-width: $break-md-rem) {
        padding-right: rem(60);

        .icons {
          display: flex;
          position: absolute;
          right: rem(6);
          top: rem(7);
        }

        .icon-account {
          fill: $text-color;
        }

        .icon {
          margin: 0 0 0 rem(5);
        }
      }
    }
  }

  &-info-left {
    align-items: center;
    display: flex;
  }

  &-info-right {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }

  &-entries-info {
    margin-left: rem(10);
  }

  &-paginate-button {
    background: $light-text-color;
    border: rem(1) solid $brand-color-2;
    cursor: pointer;
    display: inline-block;
    line-height: rem(30);
    margin-left: rem(5);
    min-width: rem(30);
    padding: 0 rem(5);
    text-align: center;
    transition: none;

    &.current {
      color: $brand-color-1;
    }

    &:focus {
      background: $brand-color-4;
      border-color: darken($brand-color-4, 10%);
      color: $light-text-color;
      outline: 0;
    }

    &[data-focus-method="mouse"],
    &[data-focus-method="touch"] {
      &:focus {
        background: $light-text-color;
        border-color: $brand-color-2;
        color: $brand-color-1;
      }
    }
  }

  &-processing {
    background: $brand-color-3;
    line-height: rem(32);
    margin-top: rem(10);
    text-align: center;
  }

  &-edit {
    margin: rem(4) 0;
  }

  &-delete {
    margin: rem(4) 0;
  }

  &-highlight {
    background: $brand-color-11;
    color: $light-text-color;
    display: inline-block;
    padding: 0 rem(4);
  }

  &-schema {
    &-1 {
      display: block;
      padding: rem(9) rem(6) rem(9) rem(14);

      &:after {
        border-left: rem(8) solid $brand-color-8;
        bottom: rem(1);
        content: "";
        left: 0;
        position: absolute;
        top: rem(1);
      }
    }

    &-2 {
      display: block;
      padding: rem(9) rem(6) rem(9) rem(14);

      &:after {
        border-left: rem(8) solid $brand-color-6;
        bottom: rem(1);
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: rem(1);
      }
    }

    &-3 {
      display: block;
      padding: rem(9) rem(6) rem(9) rem(14);

      &:after {
        border-left: rem(8) solid $brand-color-5;
        bottom: rem(1);
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: rem(1);
      }
    }

    &-4 {
      display: block;
      padding: rem(9) rem(6) rem(9) rem(14);

      &:after {
        border-left: rem(8) solid $brand-color-4;
        bottom: rem(1);
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: rem(1);
      }
    }
  }
}


//------------------------------------------------------------------------------
// FOLDERS

.folders {
  &-department {
    width: rem(135);
  }

  &-status {
    width: rem(140);
  }
}


//------------------------------------------------------------------------------
// JUCO SUMMARY

.juco-summary {
  list-style: none;
  padding: 0;

  &-title {
    background: darken($brand-color-3, 5%);
    color: $brand-color-4;
    padding: rem(4) rem(12);
  }

  &-content {
    margin: 0;
    padding: 0 rem(12);
  }

  &-date {
    display: inline-block;
    margin-bottom: rem(8);
  }

  &-add {
    border-bottom: rem(2) solid $brand-color-2;
    padding-bottom: rem(10);
  }

  &-edit {
    margin-top: rem(10);
    padding: 0 rem(12);
  }

  .buttons {
    min-width: rem(70);
    text-align: right;
    user-select: none;
  }

  .delete {
    margin-right: rem(5);
  }
}


//------------------------------------------------------------------------------
// PRACTISE

.practise {
  &-type {
    min-width: rem(90);
  }

  &-edit {
    padding-bottom: 0;
    padding-top: 0;
    vertical-align: middle;
    width: rem(30);
  }
}


//------------------------------------------------------------------------------
// REFERENCE PERSON

.reference {
  &-relationship {
    width: rem(120);
  }
}


//------------------------------------------------------------------------------
// CAPABILITY ANALYSIS

.capability-analysis {
  &-link {
    align-items: center;
    color: $brand-color-4;
    display: flex;
    text-decoration: none;

    .icon {
      display: none;
      fill: $brand-color-4;
      flex: 0 0 rem(24);
      height: rem(24);
      margin-right: rem(6);
      width: rem(24);
    }

    &[aria-expanded="true"] {
      .up {
        display: block;
      }
    }

    &[aria-expanded="false"] {
      .down {
        display: block;
      }
    }
  }

  &-header {
    text-transform: initial;
  }

  &-label {
    font-size: rem(17);
    font-weight: 700;
    margin-bottom: rem(10);
    margin-top: rem(10);
  }

  &-form-wrapper {
    margin-top: rem(20);
  }

  &-form {
    margin-top: rem(10);
  }

  &-content {
    margin-bottom: rem(24);
    margin-left: rem(24);
  }
}


.formset-line-divider {
  border-bottom: 0.0625rem solid #BCC6D3;
  margin-bottom: rem(20);
  margin-top: rem(40);
  padding-top: rem(0.5);
}

//------------------------------------------------------------------------------
// MELBA PROFILE

.melba-profile {
  &-table {
    border-collapse: collapse;
    border: none;

    td {
      border: rem(1) solid $brand-color-7;
      border-collapse: collapse;
    }

    tr:first-child td {
      border-top: none;
    }

    tr:last-child td {
      border-bottom: none;
    }

    tr td:first-child {
      border-left: none;
    }

    tr td:last-child {
      border-right: none;
    }
  }

  &-header {
    &-choice {
      text-align: center;
    }
  }

  &-category-name {
    font-weight: 700;
    text-align: right;
    background-color: darken($brand-color-3, 7);
    pointer-events: none;
  }

  &-choice {
    font-weight: 1000;
    text-align: center;
  }
}


//------------------------------------------------------------------------------
// HISTORY

.history {
  &-header {
    border-bottom: rem(1) solid lighten($brand-color-2, 5%);
    border-top: rem(1) solid lighten($brand-color-2, 5%);
    margin-bottom: rem(30);
  }

  &-nav {
    ul {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
    }

    a {
      align-items: center;
      display: flex;
      margin-right: rem(14);
      padding: rem(6) 0;
      text-decoration: none;
      text-transform: uppercase;

      &.active {
        color: $brand-color-1;
      }

      svg {
        fill: $text-color;
        height: rem(24);
        margin-right: rem(6);
        width: rem(24);
      }
    }
  }

  &-next-date {
    align-items: center;
    display: flex;
    margin: 0;
    padding: rem(6) 0;

    svg {
      fill: $brand-color-10;
      height: rem(24);
      margin-right: rem(6);
      width: rem(24);
    }
  }

  &-reading {
    list-style: none;
    padding: 0;

    &-link {
      background: darken($brand-color-3, 5%);
      color: $brand-color-4;
      display: flex;
      line-height: rem(24);
      padding: rem(4) rem(12);
      text-decoration: none;

      &:hover {
        .icon {
          fill: $brand-color-1;
        }
      }

      .title {
        flex: 1 1 auto;
        overflow: hidden;
        padding-right: rem(10);
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .icon {
        display: none;
        fill: $brand-color-4;
        flex: 0 0 rem(24);
        height: rem(24);
        width: rem(24);
      }

      &[aria-expanded="true"] {
        .up {
          display: block;
        }
      }

      &[aria-expanded="false"] {
        .down {
          display: block;
        }
      }
    }

    .note {
      margin-top: rem(5);
    }

    .edit {
      align-items: center;
      display: inline-flex;
      padding-left: 0;
      padding-right: 0;
      width: auto;

      &-wrapper {
        border-bottom: rem(2) solid lighten($brand-color-2, 5%);
        margin-bottom: rem(10);
        padding-bottom: rem(10);
      }

      .text {
        display: inline-block;
        margin-left: rem(6);
      }
    }
  }

  &-content {
    margin-bottom: rem(24);
    padding: 0 rem(12);

    .participants,
    .subject,
    .creator {
      border-bottom: rem(2) solid lighten($brand-color-2, 5%);
      margin-bottom: rem(10);
      padding-bottom: rem(10);
    }

    .internal-note {
      align-items: center;
      display: inline-flex;
      padding-left: 0;
      padding-right: 0;
      width: auto;

      &-wrapper {
        margin-top: rem(10);
        max-width: 100ch;
      }

      .text {
        display: inline-block;
        margin-left: rem(6);
      }
    }

    .note {
      max-width: 100ch;
    }

    .icon {
      height: rem(24);
      margin-left: rem(2);
      padding-top: rem(2);
      width: rem(24);
    }
  }
}

.company-history-date {
  width: rem(130);
}

.company-history-creator {
  width: rem(230);
}


//------------------------------------------------------------------------------
// ATTACHMENT LIST

.attachment-list {
  border-top: rem(1) solid lighten($brand-color-2, 5%);
  list-style: none;
  padding: 0;

  li {
    border-bottom: rem(1) solid lighten($brand-color-2, 5%);

    &:hover {
      background: lighten($brand-color-2, 17%);
    }
  }

  .file-details {
    display: block;
    padding: rem(9) rem(12);
  }

  .file-name,
  .project-name,
  .supervisor {
    display: inline-block;
    margin-bottom: rem(4);
    outline-offset: 0;
    word-break: break-all;
  }

  .project-details {
    display: block;
    padding: 0 rem(12) rem(9);

    @media (min-width: $break-md-rem) {
      padding: rem(9) rem(12);
    }
  }

  .project-name {
    font-size: 90%;

    @media (min-width: $break-md-rem) {
      font-size: 100%;
    }
  }

  .created-on,
  .supervisor,
  .category {
    display: block;
    font-size: 90%;
    margin-right: rem(10);

    @media (min-width: $break-md-rem) {
      font-size: 100%;
    }
  }

  .buttons {
    min-width: rem(70);
    text-align: right;
    user-select: none;
  }

  .edit {
    margin-left: auto;
  }

  .delete {
    margin-right: rem(5);
  }

  .edit,
  .delete {
    margin-bottom: rem(4);
    margin-top: rem(4);
  }

  &-info {
    margin-top: rem(10);
  }
}


//------------------------------------------------------------------------------
// CHECK ACT

.check-act {
  list-style: none;
  padding: 0;

  &-item {
    display: flex;
    margin-bottom: rem(5);
  }

  svg {
    height: rem(22);
    position: relative;
    top: rem(-1);
    width: rem(22);
  }

  .success {
    display: block;
    fill: $brand-color-10;
    height: rem(22);
    margin-right: rem(6);
    width: rem(22);
  }

  .error {
    display: block;
    fill: $brand-color-1;
    height: rem(22);
    margin-right: rem(6);
    width: rem(22);
  }
}


//------------------------------------------------------------------------------
// PARALLEL CARE

.parallel-care {
  &-warning {
    display: flex;
    margin-top: rem(14);

    .icon {
      fill: $brand-color-1;
      flex: 0 0 auto;
      height: rem(24);
      left: rem(-5);
      margin: 0 rem(2) 0;
      position: relative;
      top: rem(-2);
      width: rem(24);
    }
  }
}


//------------------------------------------------------------------------------
// ACT DUPLICATES

.act-duplicates-list {
  border-top: rem(1) solid lighten($brand-color-2, 5%);
  list-style: none;
  padding: 0;

  li {
    border-bottom: rem(1) solid lighten($brand-color-2, 5%);
  }

  .act {
    padding: rem(9) rem(12);
  }

  .add {
    padding: rem(4) 0 rem(12) rem(12);
    text-align: right;

    @media (min-width: $break-sm-rem) {
      padding-top: rem(12);
    }

    @media (min-width: $break-lg-rem) {
      padding: rem(12) 0 rem(9) rem(12);
    }
  }
}

//------------------------------------------------------------------------------
// PARALLEL CARE LIST

.parallel-care-list {
  border-top: rem(1) solid lighten($brand-color-2, 5%);
  list-style: none;
  padding: 0;

  li {
    border-bottom: rem(1) solid lighten($brand-color-2, 5%);

    &:hover {
      background: lighten($brand-color-2, 17%);
    }
  }

  .info {
    padding: rem(9) rem(12);
  }

  .buttons {
    min-width: rem(70);
    text-align: right;
    user-select: none;
  }

  .delete {
    margin-bottom: rem(4);
    margin-right: rem(5);
    margin-top: rem(4);
  }
}

//------------------------------------------------------------------------------
// VERSION NUMBER

.version-nr {
  color: $brand-color-4;
  margin-top: 0;
  text-align: right;
}

//------------------------------------------------------------------------------
// REQUIRED FIELD

.required-at-end {
  color: $brand-color-11;
  margin-left: rem(3);
}

.delete-holiday-representative {
  margin-bottom: rem(8);
}


//------------------------------------------------------------------------------
// PREVENT DOUBLE CLICK, LOCK BUTTON AFTER FIRST CLICK

.btn-loader {
  cursor: default;
  pointer-events: none;
  position: relative;
}


//------------------------------------------------------------------------------
// LOGIN-AS

.fixed_alert_container {
  background-image: linear-gradient(to bottom, #FCF8E3 0%, #F8EFC0 100%);
  background-repeat: repeat-x;
  border-color: #F5E79E;
  font-size: rem(15);
  padding: rem(9);
}

.svg-inline {
  height: 1.25em;
  vertical-align: -0.225em;
  width: 1.25em;
}


//------------------------------------------------------------------------------
// CARD

.card {
  border: rem(2) solid #ADB9C9;
  border-radius: rem(10);
  margin-bottom: rem(20);
}

.card-body {
  padding: rem(10);
}

.filter-collapse {
  @media screen and (min-width: rem(1329)) {
    display: none;
  }
}

.table-filter-collapse {
  @media screen and (max-width: rem(1329)) {
    display: none;
  }
}

.text-muted {
  color: #777!important;
}
