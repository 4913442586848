//------------------------------------------------------------------------------
// COLORS

$brand-color-1: #E3000F;
$brand-color-2: #ADB9C9;
$brand-color-3: #EBEFF3;
$brand-color-4: #374355;
$brand-color-5: #FF5F5A;
$brand-color-6: #4191CD;
$brand-color-7: #8393A7;
$brand-color-8: #91C36E;
$brand-color-9: #EF771A;

$brand-color-10: #35AA6C;
$brand-color-11: #3566AA;

$brand-color-12: #9C9D9D;

$body-color: #FFF;
$text-color: #1A1A1A;
$light-text-color: #FFF;


//------------------------------------------------------------------------------
// FONTS

$line-height: 1.4;

$font-size-min: 14;
$font-size-max: 15;
$font-size-lg-min: 15;
$font-size-lg-max: 16;
$font-size-h1-min: 20;
$font-size-h1-max: 26;
$font-size-h2-min: 18;
$font-size-h2-max: 20;
$font-size-h3-min: 15;
$font-size-h3-max: 16;
$font-size-h4-min: 14;
$font-size-h4-max: 15;
$font-size-input: 16;
$font-size-table: 14;


//------------------------------------------------------------------------------
// BREAKPOINTS

$break-sm: 480;
$break-md: 768;
$break-lg: 992;
$break-xl: 1200;

$break-sm-em: em($break-sm);
$break-md-em: em($break-md);
$break-lg-em: em($break-lg);
$break-xl-em: em($break-xl);

$break-sm-rem: rem($break-sm);
$break-md-rem: rem($break-md);
$break-lg-rem: rem($break-lg);
$break-xl-rem: rem($break-xl);


//------------------------------------------------------------------------------
// GRID

$container-min-width: 320;
$container-max-width: 1800;

$col-form-gutter: 5;

$col-min-gutter: 10;
$col-max-gutter: 50;
