//------------------------------------------------------------------------------
// FUNCTIONS

@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

@function rem($px) {
  @return #{$px / 16}rem;
}

@function rem-unitless($px) {
  @return #{$px / 16};
}

@function em($px) {
  @return #{$px / 16}em;
}
