// stylelint-disable declaration-no-important

@import "x_scss_helpers/_functions.scss";

.data-table {
  --x-progress-height: #{rem(12)};
  --x-progress-wrapper-padding: #{rem(6)};
  border-bottom: var(--x-table-border-width) solid var(--x-table-border-color);
  margin-bottom: var(--x-spacer);

  .form-control {
    min-width: rem(75);
  }

  thead {
    tr:first-child {
      th {
        border-bottom: 0;
        box-shadow: 0 calc(var(--x-table-border-width) * 2) 0 var(--x-table-border-color);
        position: sticky;
        top: var(--x-table-fixed-header-offset);
        z-index: 200;
      }
    }
  }

  tbody {
    td {
      &:focus {
        background-color: var(--x-anchor-focus-color);
        color: var(--x-white);
        outline: 0;
      }

      &[data-focus-method="mouse"],
      &[data-focus-method="touch"] {
        &:focus {
          background-color: transparent;
          color: var(--x-body-color);
        }
      }
    }

    tr.dtrg-group {
      td {
        background: var(--x-datatable-group-bg-color);
      }
    }
  }

  tfoot {
    display: table-header-group;
  }

  &.collapsed {
    tbody {
      tr:not(.dtrg-group) {
        td:not(.dataTables_empty) {
          &:first-child {
            cursor: pointer;
            position: relative;

            &:before {
              background: var(--x-body-color);
              content: "";
              display: block;
              height: rem(20);
              left: 50%;
              margin-left: rem(-10);
              mask-image: escape-svg(url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path d='M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z' /></svg>"));
              position: absolute;
              width: rem(20);
            }
          }

          &.child {
            padding-left: var(--x-table-cell-padding);

            &:first-child {
              cursor: default;

              &:before {
                display: none;
              }
            }
          }

          &.datatable-empty {
            background: transparent;
            cursor: default;
            padding-left: rem(12);
          }
        }

        &.parent {
          td {
            &:first-child {
              &:before {
                mask-image: escape-svg(url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path d='M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z' /></svg>"));
              }
            }
          }
        }
      }
    }
  }

  .dtr-details {
    list-style: none;
    margin: calc(var(--x-spacer) / 2) 0 0;
    padding: 0;

    .icon-table {
      display: inline-flex;
    }
  }

  .dtr-title {
    display: block;
  }

  .dtr-data {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: var(--x-spacer);
    overflow-wrap: break-word;
    word-break: break-word;

    [data-modal-link] {
      margin: 0 calc(var(--x-spacer) / 2) 0 0;
    }
  }

  .sorting-asc,
  .sorting-desc,
  .sorting {
    cursor: pointer;
    padding-left: calc(var(--x-spacer) + #{rem(25)});
    position: relative;

    &:before {
      bottom: var(--x-table-cell-padding-sm);
      content: "";
      display: block;
      height: rem(20);
      left: var(--x-spacer);
      mask-repeat: no-repeat;
      mask-size: rem(20) rem(20);
      position: absolute;
      width: rem(20);
    }

    &:focus {
      color: var(--x-anchor-focus-color);
      outline: 0;
    }

    &:first-child {
      background-position: left calc(var(--x-spacer) * 2) bottom rem(9);
      padding-left: calc(var(--x-spacer) * 2 + #{rem(25)});
    }
  }

  .sorting {
    &:before {
      background-color: var(--x-body-color);
      mask-image: escape-svg(url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path d='M10,13V11H18V13H10M10,19V17H14V19H10M10,7V5H22V7H10M6,17H8.5L5,20.5L1.5,17H4V7H1.5L5,3.5L8.5,7H6V17Z'/></svg>"));
    }

    &:focus {
      background-color: var(--x-anchor-focus-color)!important;
      border-color: var(--x-body-color);
      color: var(--x-white);

      &:before {
        background-color: var(--x-white);
      }
    }

    &[data-focus-method="mouse"],
    &[data-focus-method="touch"] {
      &:focus {
        background-color: var(--x-gray-200)!important;
        color: var(--x-body-color);

        &:before {
          background-color: var(--x-body-color);
        }
      }
    }
  }

  .sorting-asc {
    background-color: var(--x-gray-400)!important;

    &:before {
      background-color: var(--x-body-color);
      mask-image: escape-svg(url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path d='M10,11V13H18V11H10M10,5V7H14V5H10M10,17V19H22V17H10M6,7H8.5L5,3.5L1.5,7H4V20H6V7Z' /></svg>"));
    }

    &:focus {
      background-color: var(--x-anchor-focus-color)!important;
      border-color: var(--x-body-color);
      color: var(--x-white);

      &:before {
        background-color: var(--x-white);
      }
    }

    &[data-focus-method="mouse"],
    &[data-focus-method="touch"] {
      &:focus {
        background-color: var(--x-gray-200)!important;
        color: var(--x-body-color);

        &:before {
          background-color: var(--x-body-color);
        }
      }
    }
  }

  .sorting-desc {
    background-color: var(--x-gray-400)!important;

    &:before {
      background-color: var(--x-body-color);
      mask-image: escape-svg(url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path d='M10,13V11H18V13H10M10,19V17H14V19H10M10,7V5H22V7H10M6,17H8.5L5,20.5L1.5,17H4V4H6V17Z' /></svg>"));
    }

    &:focus {
      background-color: var(--x-anchor-focus-color)!important;
      border-color: var(--x-body-color);
      color: var(--x-white);

      &:before {
        background-color: var(--x-white);
      }
    }

    &[data-focus-method="mouse"],
    &[data-focus-method="touch"] {
      &:focus {
        background-color: var(--x-gray-200)!important;
        color: var(--x-body-color);

        &:before {
          background-color: var(--x-body-color);
        }
      }
    }
  }

  .processing {
    background: var(--x-datatable-loader-bg-color);
    border: 0;
    display: table-cell!important;
  }

  .spinner-wrapper {
    padding-left: calc(var(--x-spinner-height-sm) + var(--x-spacer));
    position: relative;
  }

  .spinner-border-sm {
    left: 0;
    margin-top: calc(var(--x-spinner-height-sm) / -2);
    position: absolute;
    top: 50%;
  }

  .progress-wrapper {
    padding: var(--x-progress-wrapper-padding) 0;
  }
}

//------------------------------------------------------------------------------
// TABLE COUNTS

.table-counts {
  margin-top: 1rem;
}

.table-count {
  border-left: rem(5) solid lighten($brand-color-2, 5%);
  font-weight: 700;
  margin-right: 1.5rem;
  margin-top: 1rem;
  min-width: 2rem;
  padding-left: 0.5rem;
}

//------------------------------------------------------------------------------
// TABLE CUSTOM FILTERS

.custom-filters {
  background-color: unset;
  border: none;
  font-size: 1rem;
  font-weight: 700;
  min-width: 2.5rem;
  padding: 0.5rem 0.75rem;

  &:hover {
    background-color: lighten($brand-color-2, 5%);
  }
}

.custom-filter-active {
  background-color: $brand-color-1;
  color: white;

  &:hover {
    background-color: $brand-color-1;
    color: white;
  }
}

.custom-filters-letters {
  border-left: rem(1) solid lighten($brand-color-2, 5%);
}
